import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import heroStyles from '../components/hero.module.css'
import Img from 'gatsby-image'

class ContactIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const [headerImage] = get(this, 'props.data.allContentfulAsset.edges')

    return (
      <Layout location={this.props.location} pageName={'contact'} >
        <div style={{ background: '#fff' }}>
          <Helmet title={'Contact | ' + siteTitle} defer={false}/>
          <div className={heroStyles.hero}>
            <Img className={heroStyles.heroImage} alt={headerImage.node.title} fluid={headerImage.node.fluid} />
          </div>
          <div className="wrapper">
            <h1 className="section-headline">Contact Me</h1>
            <p>Schedule a free 30 minute call.</p>
            <a href={"https://calendly.com/warnero/intro"} className={"btn btn-blue"}>Schedule Call</a>
            <p>Or fill this form out and I'll get back to you</p>
            <form method="post" action="https://formspree.io/xdowzvjn">
              <label>
                Name
                <input type="text" name="name" id="name" />
              </label>
              <label>
                Email
                <input type="email" name="email" id="email" />
              </label>
              <label>
                Company/Site
                <input type="company_site" name="company_site" id="company_site" />
              </label>
              <label>
                Project Description
                <textarea name="message" id="message" rows="5" />
              </label>
              <label>
                Budget
                <select name="budget" id="budget">
                  <option value="10k">0 - 10k</option>
                  <option value="25k">11k - 25k</option>
                  <option value="50k">26k - 50k</option>
                  <option value="50k+">50k+</option>
                </select>
              </label>
              <button type={"reset"} className={"btn btn-grey"}>Clear</button>
              <button type={"submit"} className={"btn btn-blue"}>Send</button>
            </form>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ContactIndex

export const pageQuery = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPerson(filter: { contentful_id: { eq: "5698EQXlOUFK2Ur4uMGeV5" } }) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
    allContentfulAsset(filter: {contentful_id: {eq: "4B1tvaEsYbt9oCq6ZwWhkT"}}) {
      edges {
        node {
          title
          fluid(maxWidth: 1180, background: "rgb:000000") {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
      }
    }
  }
`
